// // // import React, { useState } from 'react';
// // // import { Space, Switch, Table } from 'antd';
// // // const columns = [
// // //     {
// // //         title: 'Name',
// // //         dataIndex: 'name',
// // //         key: 'name',
// // //     },
// // //     {
// // //         title: 'Age',
// // //         dataIndex: 'age',
// // //         key: 'age',
// // //         width: '12%',
// // //     },
// // //     {
// // //         title: 'Address',
// // //         dataIndex: 'address',
// // //         width: '30%',
// // //         key: 'address',
// // //     },
// // // ];
// // // const data = [
// // //     {
// // //         key: 1,
// // //         name: 'John Brown sr.',
// // //         age: 60,
// // //         address: 'New York No. 1 Lake Park',
// // //         children: [
// // //             {
// // //                 key: 11,
// // //                 name: 'John Brown',
// // //                 age: 42,
// // //                 address: 'New York No. 2 Lake Park',
// // //             },
// // //             {
// // //                 key: 12,
// // //                 name: 'John Brown jr.',
// // //                 age: 30,
// // //                 address: 'New York No. 3 Lake Park',
// // //                 children: [
// // //                     {
// // //                         key: 121,
// // //                         name: 'Jimmy Brown',
// // //                         age: 16,
// // //                         address: 'New York No. 3 Lake Park',
// // //                     },
// // //                 ],
// // //             },
// // //             {
// // //                 key: 13,
// // //                 name: 'Jim Green sr.',
// // //                 age: 72,
// // //                 address: 'London No. 1 Lake Park',
// // //                 children: [
// // //                     {
// // //                         key: 131,
// // //                         name: 'Jim Green',
// // //                         age: 42,
// // //                         address: 'London No. 2 Lake Park',
// // //                         children: [
// // //                             {
// // //                                 key: 1311,
// // //                                 name: 'Jim Green jr.',
// // //                                 age: 25,
// // //                                 address: 'London No. 3 Lake Park',
// // //                             },
// // //                             {
// // //                                 key: 1312,
// // //                                 name: 'Jimmy Green sr.',
// // //                                 age: 18,
// // //                                 address: 'London No. 4 Lake Park',
// // //                             },
// // //                         ],
// // //                     },
// // //                 ],
// // //             },
// // //         ],
// // //     },
// // //     {
// // //         key: 2,
// // //         name: 'Joe Black',
// // //         age: 32,
// // //         address: 'Sydney No. 1 Lake Park',
// // //     },
// // // ];

// // // // rowSelection objects indicates the need for row selection
// // // const rowSelection = {
// // //     onChange: (selectedRowKeys, selectedRows) => {
// // //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
// // //     },
// // //     onSelect: (record, selected, selectedRows) => {
// // //         console.log(record, selected, selectedRows);
// // //     },
// // //     onSelectAll: (selected, selectedRows, changeRows) => {
// // //         console.log(selected, selectedRows, changeRows);
// // //     },
// // // };
// // // const TestApp = () => {

// // //     return (
// // //         <>
// // //             <Table
// // //                 columns={columns}
// // //                 rowSelection={{
// // //                     ...rowSelection,

// // //                 }}
// // //                 dataSource={data}
// // //             />
// // //         </>
// // //     );
// // // };
// // // export default TestApp;

// // import React, { useState } from 'react';
// // import { Button, Card, Form, Input, Space, Typography, message } from 'antd';
// // import { CloseOutlined } from '@ant-design/icons';

// // const TestApp = () => {
// //     const [form] = Form.useForm();
// //     const [fixedParentId, setFixedParentId] = useState('');
// //     const [startFNo, setStartFNo] = useState('');

// //     const applyFixedParentId = () => {
// //         const items = form.getFieldValue('items') || [];
// //         const updatedItems = items.map(item => ({
// //             ...item,
// //             parentId: fixedParentId, // Apply fixedParentId to all items
// //         }));
// //         form.setFieldsValue({ items: updatedItems });
// //         message.success('Parent ID applied to all items!');
// //     };

// //     const applyStartFNo = () => {
// //         const startNumber = parseFloat(startFNo);
// //         if (isNaN(startNumber)) {
// //             message.error('Invalid start number for fNo!');
// //             return;
// //         }
// //         const items = form.getFieldValue('items') || [];
// //         const updatedItems = items.map((item, index) => ({
// //             ...item,
// //             fNo: (startNumber + index * 0.1).toFixed(1), // Increment fNo by 0.1 for each item
// //         }));
// //         form.setFieldsValue({ items: updatedItems });
// //         message.success('fNo applied to all items!');
// //     };

// //     return (
// //         <Form
// //             labelCol={{ span: 6 }}
// //             wrapperCol={{ span: 18 }}
// //             form={form}
// //             name="dynamic_form_complex"
// //             style={{ maxWidth: 600 }}
// //             autoComplete="off"
// //             initialValues={{ items: [{}] }}
// //         >
// //             <Space style={{ marginBottom: 20 }} align="baseline">
// //                 <Form.Item name="fixedParentId" label="Fixed Parent ID">
// //                     <Input placeholder="Enter fixed Parent ID" value={fixedParentId} onChange={e => setFixedParentId(e.target.value)} />
// //                 </Form.Item>
// //                 <Button type="primary" onClick={applyFixedParentId}>Apply Parent ID to All</Button>

// //                 <Form.Item name="startFNo" label="Start fNo">
// //                     <Input placeholder="Enter start fNo" value={startFNo} onChange={e => setStartFNo(e.target.value)} />
// //                 </Form.Item>
// //                 <Button type="primary" onClick={applyStartFNo}>Apply fNo to All</Button>
// //             </Space>

// //             <Form.List name="items">
// //                 {(fields, { add, remove }) => (
// //                     <>
// //                         {fields.map(field => (
// //                             <Card
// //                                 size="small"
// //                                 title={`Item ${field.name + 1}`}
// //                                 key={field.key}
// //                                 extra={<CloseOutlined onClick={() => remove(field.name)} />}
// //                             >
// //                                 <Form.Item label="Name" name={[field.name, 'name']}>
// //                                     <Input />
// //                                 </Form.Item>
// //                                 <Form.Item label="Parent ID" name={[field.name, 'parentId']}>
// //                                     <Input placeholder="Parent ID" />
// //                                 </Form.Item>
// //                                 <Form.Item label="fNo" name={[field.name, 'fNo']}>
// //                                     <Input placeholder="fNo" />
// //                                 </Form.Item>
// //                                 {/* Additional fields can be placed here */}
// //                             </Card>
// //                         ))}
// //                         <Button type="dashed" onClick={() => add()} block style={{ marginTop: 16 }}>
// //                             + Add Item
// //                         </Button>
// //                     </>
// //                 )}
// //             </Form.List>

// //             <Form.Item noStyle shouldUpdate>
// //                 {() => (
// //                     <Typography>
// //                         <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
// //                     </Typography>
// //                 )}
// //             </Form.Item>
// //         </Form>
// //     );
// // };


// // export default TestApp;
// import React, { useState } from 'react';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { Input } from 'antd';

// function TestApp() {
//     const [code, setCode] = useState('');

//     const handleCodeChange = (e) => {
//         setCode(e.target.value);
//     };

//     return (
//         <div>
//             <Input.TextArea
//                 value={code}
//                 onChange={handleCodeChange}
//                 placeholder="Enter your code here..."

//                 style={{ fontSize: '10px' }} // 调整字号大小
//                 rows={10}
//             />
//             <SyntaxHighlighter language="jsx" style={solarizedlight}
//                 lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }} // 自动换行样式
//                 wrapLines // 启用自动换行
//             >
//                 {code}
//             </SyntaxHighlighter>
//         </div>
//     );
// }


// export default TestApp;


import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import axios from 'axios';

const TwstApp = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('/.netlify/functions/login', values);
            const { token } = response.data;
            console.log('Login successful. Token:', token);
            // 存储 token，用于后续请求的认证
            setVisible(false);
        } catch (error) {
            console.error('Login failed:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                登录
            </Button>
            <Modal
                title="登录"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name="username"
                        label="用户名"
                        rules={[{ required: true, message: '请输入用户名' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="密码"
                        rules={[{ required: true, message: '请输入密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TwstApp;