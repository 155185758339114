import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import IntroductionDrawer from '../IntroductionDrawer';

const HomePage = () => {
    return (
        <div style={{ padding: '20px' }}>

            {/* 首页介绍区域 */}
            <div style={{ textAlign: 'center', margin: '40px 0' }}>
                <h1>Welcome to Our Blockchain Application</h1>
                <p>Explore the world of blockchain and cryptocurrency with our advanced tools and features.</p>
                <Button type="primary" size="large">Get Started</Button>
                <br /> <br />
                <IntroductionDrawer
                    pageTitle="页面标题"
                    pageDescription='<h1>欢迎来到Lixxx练习平台</h1>
                    <p>这是一个个人学习练习网站，主要包括区块链相关的工具、一个聊天记录存储应用和一个WordPress个人博客(测试学习使用)。</p>
                    <h2>主要功能</h2>
                    <ul>
                        <li><strong>创建钱包：</strong>用户可以轻松创建自己的区块链钱包，学习钱包的基本原理和使用。</li>
                        <li><strong>分发归集代币：</strong>提供分发和归集代币的工具，帮助用户理解代币在区块链网络中的流动性。</li>
                        <li><strong>自定义批量执行交易或合约交互：</strong>通过这一功能，用户可以实现批量执行交易，以及与智能合约的交互，加深对智能合约应用的理解。</li>
                        <li><strong>CPFP子支付父加速BTC交易：</strong>学习和练习使用CPFP机制加速BTC交易，深入理解比特币网络的交易确认机制。</li>
                        <li><strong>聊天记录存储应用：</strong>集成了一个聊天记录存储应用，从React前端技术到FaunaDB和Netlify云函数的无服务器后端集成。</li>
                    </ul>
                    <h3>注意事项</h3>
                    <p>请注意，这个网站是为零基础学习练习使用的，所有操作和功能仅供学习之用。请不要轻易用于实际交易或其他重要用途，以避免可能产生的风险和损失。</p>
                    <footer>
                        <p>感谢您的访问，更多内容请访问 
                        
                        <a href=https://github.com/lzzsG>lzzsG</a>
                       。</p>
                      
                    </footer>'
                /><br /><br />
            </div>
            <br></br> <br></br>
            {/* 功能性模块介绍 */}
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Card title="Wallet Management" bordered={false}>
                        Securely manage your cryptocurrency wallets and transactions.
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Blockchain Explorer" bordered={false}>
                        Explore blockchain data with our comprehensive blockchain explorer.
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Smart Contract Tools" bordered={false}>
                        Deploy and interact with smart contracts on various blockchain networks.
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="钱包管理" bordered={false}>
                        管理您的加密货币钱包，进行安全的交易和资产跟踪。
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="智能合约工具" bordered={false}>
                        方便地部署和互动智能合约，体验区块链技术的强大功能。
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="区块链数据分析" bordered={false}>
                        深入分析区块链数据，获取关键洞见和市场趋势。
                    </Card>
                </Col>
            </Row>

            <br></br> <br></br>
            <Row gutter={16}>
                {/* 第一栏 */}
                <Col span={12} style={{ padding: '20px' }}>
                    <h2>实时区块链数据</h2>
                    <p>查看最新的区块链交易和市场动态。</p>
                    {/* 这里可以添加实时数据展示的组件 */}
                </Col>

                {/* 第二栏 */}
                <Col span={12} style={{ padding: '20px' }}>
                    <h2>最新资讯</h2>
                    <p>了解最新的区块链技术和市场新闻。</p>
                    <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>

                    {/* 这里可以展示最新的博客文章或新闻链接 */}
                </Col>
            </Row>
            {/* 其他信息或呼吁行动 */}
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <h2>Join Our Community</h2>
                <p>Stay updated with the latest blockchain trends and connect with other enthusiasts.</p>
                <Button type="primary" size="large">Join Now</Button>
            </div>

        </div>
    );
};

export default HomePage;