import React, { useEffect, useRef, useState } from "react";

import { Button, Card, Input } from "antd";

function WalletTest() {
    const [unisatInstalled, setUnisatInstalled] = useState(false);
    const [connected, setConnected] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [address, setAddress] = useState("");
    const [balance, setBalance] = useState({
        confirmed: 0,
        unconfirmed: 0,
        total: 0,
    });

    //bsc price
    const [prices, setPrices] = useState(null);
    useEffect(() => {
        const fetchBitcoinPrices = async () => {
            try {
                const response = await fetch('https://mempool.space/api/v1/prices');
                const data = await response.json();
                setPrices(data);
            } catch (error) {
                console.error('Error fetching Bitcoin prices:', error);
            }
        };

        fetchBitcoinPrices();
    }, []);



    const getBasicInfo = async () => {
        const unisat = window.unisat;
        const [address] = await unisat.getAccounts();
        setAddress(address);


        const balance = await unisat.getBalance();
        setBalance(balance);


    };

    const selfRef = useRef({
        accounts: [],
    });
    const self = selfRef.current;
    const handleAccountsChanged = (_accounts) => {
        if (self.accounts[0] === _accounts[0]) {
            // prevent from triggering twice
            return;
        }
        self.accounts = _accounts;
        if (_accounts.length > 0) {
            setAccounts(_accounts);
            setConnected(true);

            setAddress(_accounts[0]);

            getBasicInfo();
        } else {
            setConnected(false);
        }
    };



    useEffect(() => {
        async function checkUnisat() {
            let unisat = window.unisat;

            for (let i = 1; i < 10 && !unisat; i += 1) {
                await new Promise((resolve) => setTimeout(resolve, 100 * i));
                unisat = window.unisat;
            }

            if (unisat) {
                setUnisatInstalled(true);
            } else if (!unisat) return;

            unisat.getAccounts().then((accounts) => {
                handleAccountsChanged(accounts);
            });

            unisat.on("accountsChanged", handleAccountsChanged);


            return () => {
                unisat.removeListener("accountsChanged", handleAccountsChanged);

            };
        }

        checkUnisat().then();
    }, []);

    if (!unisatInstalled) {
        return (
            <div className="App">
                <header className="App-header">
                    <div>
                        <Button
                            onClick={() => {
                                window.location.href = "https://unisat.io";
                            }}
                        >
                            Install Unisat Wallet
                        </Button>
                    </div>
                </header>
            </div>
        );
    }
    const unisat = window.unisat;




    return (
        <div className="App">
            <header className="App-header">
                {connected ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Card className="card-hoverable"
                            size="small"
                            title="Basic Info"
                            style={{ width: '100%' }}
                        >
                            <div style={{ textAlign: "left", marginTop: 10 }}>
                                <div style={{ fontWeight: "bold" }}>Address:</div>
                                <div style={{ wordWrap: "break-word" }}>{address}</div>
                            </div>



                            <div style={{ textAlign: "left", marginTop: 10 }}>
                                <div style={{ fontWeight: "bold" }}>Balance:</div>
                                <div style={{ wordWrap: "break-word" }}>{balance.total} sats / {balance.total / 100000000} BTC / {prices && prices.USD ? (prices.USD * balance.total / 100000000).toFixed(2) : 'N/A'} USD </div>


                            </div>
                        </Card>

                        <br></br>

                        <SignPsbtCard />

                    </div>
                ) : (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",

                    }}>
                        <Button
                            onClick={async () => {
                                const result = await unisat.requestAccounts();
                                handleAccountsChanged(result);
                            }}
                        >
                            Connect Unisat Wallet
                        </Button>
                    </div>
                )}
            </header>
        </div>
    );
}
const { TextArea } = Input;
function SignPsbtCard() {
    const [psbtHex, setPsbtHex] = useState("");
    const [psbtResult, setPsbtResult] = useState("");
    return (
        <Card className="card-hoverable" size="small" title="Sign Psbt" style={{ width: '100%' }}>
            <div style={{ textAlign: "left", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>PsbtHex:</div>
                <TextArea
                    defaultValue={psbtHex}
                    onChange={(e) => {
                        setPsbtHex(e.target.value);
                    }}
                    rows={4} placeholder="inpute PsbtHex"
                ></TextArea>
            </div>
            <div style={{ textAlign: "left", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>Result:</div>
                <div style={{ wordWrap: "break-word" }}>{psbtResult}</div>
            </div>
            <Button
                style={{ marginTop: 10 }}
                onClick={async () => {
                    try {
                        const psbtResult = await window.unisat.signPsbt(psbtHex);
                        setPsbtResult(psbtResult);
                    } catch (e) {
                        setPsbtResult(e.message);
                    }
                }}
            >
                Sign Psbt
            </Button>
        </Card>
    );
}



export default WalletTest;