import React, { useState, useRef, useEffect } from 'react';
import {
    Card, Button, Radio, Input, Modal, Col, Row, Table,
    Form, InputNumber, Select, Space, Switch,
} from 'antd';
import { KeyOutlined, CheckOutlined, CloseOutlined, WalletOutlined, SettingOutlined, CalendarOutlined } from '@ant-design/icons';
import { ethers } from 'ethers';
import './contractStyles.css';
import IntroductionDrawer from '../IntroductionDrawer';


const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};


const Contract = () => {
    const [form] = Form.useForm();

    // 交互日志模块

    const [logs, setLogs] = useState([]);
    const logContainerRef = useRef(null);

    const addLog = (message) => {
        setLogs((prevLogs) => [...prevLogs, message]);
        console.log(message);
    };

    const handleSomeAction = () => {
        addLog(`--------执行了某些操作--------`);
    };

    const clearLogs = () => {
        setLogs([]);
    };

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logs]);

    //ethers
    const [provider, setProvider] = useState(null);

    const predefinedChains = {
        ethereum: 'https://eth.llamarpc.com',
        BSC: 'https://bsc.publicnode.com',
        avalanche: 'https://avalanche.drpc.org',
        sepolia: 'https://sepolia.rpc.thirdweb.com',

    };

    const [customRpcUrl, setCustomRpcUrl] = useState('');
    const [walletData, setWalletData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [privateKeys, setPrivateKeys] = useState('');
    const [interactionType, setInteractionType] = useState('a'); // 'a' for MetaMask, 'b' for private key


    useEffect(() => {
        // 初始化数据
        setWalletData([]);
    }, []);

    const handleChainChange = (value) => {
        const newRpcUrl = predefinedChains[value] || customRpcUrl;
        setCustomRpcUrl(newRpcUrl);
        const newProvider = new ethers.providers.JsonRpcProvider(newRpcUrl);
        setProvider(newProvider);
    };

    const handleCustomRpcUrlChange = (e) => {
        const newRpcUrl = e.target.value;
        setCustomRpcUrl(newRpcUrl);
        const newProvider = new ethers.providers.JsonRpcProvider(newRpcUrl);
        setProvider(newProvider);
    };

    const updateBalances = async () => {
        const updatedData = await Promise.all(walletData.map(async (item) => {
            if (selectedRowKeys.includes(item.key)) {
                try {
                    const address = ethers.utils.getAddress(item.address);
                    const balance = await provider.getBalance(address);
                    return { ...item, balance: ethers.utils.formatEther(balance) };
                } catch (err) {
                    console.error('Error fetching balance for address:', item.address, err);
                    return { ...item, balance: "Error" };
                }
            }
            return item;
        }));
        setWalletData(updatedData);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleRadioChange = (e) => {
        setInteractionType(e.target.value);
    };

    const showPrivateKeyModal = () => {
        setIsModalVisible(true);
    };


    const handleModalOk = () => {
        const wallets = privateKeys.split('\n').map((key, index) => {
            try {
                const trimmedKey = key.trim();
                const wallet = new ethers.Wallet(trimmedKey);
                return {
                    key: index,
                    address: wallet.address,
                    privateKey: trimmedKey,
                    balance: 0.00,
                    state: '-',
                };
            } catch (err) {
                console.error('Invalid private key:', key, err);
                return null;
            }
        }).filter(wallet => wallet !== null);

        setWalletData(wallets);
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: '钱包地址',
            dataIndex: 'address',
        },
        {
            title: '余额',
            dataIndex: 'balance',
            render: (text) => `${parseFloat(text).toFixed(18)}`,
        },
        {
            title: '状态',
            dataIndex: 'state',
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const buildTransaction = (values) => {
        const { baseFee, priorityFee, gasPrice, value, target, data } = values;

        let transaction = {
            to: target,
            value: ethers.utils.parseEther(value),
            gasLimit: ethers.BigNumber.from(values.gasLimit),
            data: data,
        };

        if (useEIP1559) {
            transaction.maxFeePerGas = ethers.utils.parseUnits(baseFee, 'gwei');
            transaction.maxPriorityFeePerGas = ethers.utils.parseUnits(priorityFee, 'gwei');
            addLog(`Transaction to: ${target}, Value: ${value}, EIP-1559: ${useEIP1559}, maxFeePerGas: ${transaction.maxFeePerGas}, maxPriorityFeePerGas: ${transaction.maxPriorityFeePerGas}, Data: ${data}`);
        } else {
            transaction.gasPrice = ethers.utils.parseUnits(gasPrice, 'gwei');
            addLog(`Transaction to: ${target}, Value: ${value}, EIP-1559: ${useEIP1559}, gasPrice: ${transaction.gasPrice}, Data: ${data}`);
        }

        return transaction;
    };

    const handleMetaMaskTransaction = async (values) => {
        // 首先确保用户已安装 MetaMask
        if (!window.ethereum) {
            addLog("MetaMask 未安装");
            return;
        }
        try {
            // 请求用户连接 MetaMask 钱包
            const transaction = buildTransaction(values);
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = metaMaskProvider.getSigner();

            for (let i = 0; i < values.times; i++) {
                const txResponse = await signer.sendTransaction(transaction);
                addLog(`MetaMask 第 ${i + 1} 次交易发送成功: Hash - ${txResponse.hash}`);
                const receipt = await txResponse.wait();
                addLog(`MetaMask 第 ${i + 1} 次交易确认: Block - ${receipt.blockNumber}`);
            }
        } catch (error) {
            addLog(`MetaMask 交易错误: ${error.message}`);
        }
    };

    const [useEIP1559, setUseEIP1559] = useState(true);
    const handleTransactionSubmit = async (values) => {
        addLog(`提交的交易类型: ${interactionType}`);


        if (interactionType === 'a') {
            addLog("使用 MetaMask 发送交易");
            // 使用 MetaMask 发送交易
            await handleMetaMaskTransaction(values);
            return;
        }

        // 使用私钥发送交易
        addLog("使用私钥发送交易");
        const selectedWallets = walletData.filter(wallet => selectedRowKeys.includes(wallet.key));
        const transaction = buildTransaction(values);
        try {
            for (let wallet of selectedWallets) {
                const privateKeyProvider = new ethers.providers.JsonRpcProvider(customRpcUrl);
                for (let wallet of selectedWallets) {
                    const signer = new ethers.Wallet(wallet.privateKey, privateKeyProvider);

                    if (!ethers.utils.isHexString(values.data) || values.data.length % 2 !== 0) {
                        addLog(`错误: 'data' 字段不是有效的十六进制字符串或其长度不是偶数`);
                        continue;
                    }

                    for (let i = 0; i < values.times; i++) {
                        const txResponse = await signer.sendTransaction(transaction);
                        addLog(`第 ${i + 1} 次交易发送成功: Hash - ${txResponse.hash}`);
                        const receipt = await txResponse.wait();
                        addLog(`第 ${i + 1} 次交易确认: Block - ${receipt.blockNumber}`);
                    }
                }

            }
        } catch (error) {
            console.error("Error in sending transaction:", error);
            addLog(`Error in sending transaction: ${error.message}`);
        }
    };


    return (
        <div style={{ margin: '0 auto' }}>
            <IntroductionDrawer
                pageTitle="Iinteraction"
                pageDescription="  <h3>特点：</h3>
                <ul>
                    <li>采用最新的React Hooks API，提高了代码的可读性和维护性。</li>
                    <li>集成了Ant Design UI库，实现了丰富的用户界面和良好的用户体验。</li>
                    <li>利用了ethers.js库来与以太坊区块链进行交互，实现了基础的区块链操作功能。</li>
                    <li>自定义CSS样式提升了组件的视觉效果。</li>
                </ul>
            
                <h3>功能：</h3>
                <ul>
                    <li>批量导入钱包地址和私钥。</li>
                    <li>支持RPC URL自定义，以及预定义区块链网络的快速选择。</li>
                    <li>钱包余额状态查询功能。</li>
                    <li>允许用户通过MetaMask或私钥批量与智能合约交互。</li>
                    <li>自定义各种交易参数。批量多次交易。</li>
                    <li>支持EIP-1559交易费用模型，用户可以根据需求选择交易费用模式。</li>
                    <li>提供了一个日志模块，用于记录用户的操作和交易结果。</li>
                </ul>"
            /><br /><br />
            <Card className="card-hoverable" title={<span>交互钱包 <WalletOutlined /></span>} >
                <div>
                    <Radio.Group defaultValue="a" buttonStyle="solid" onChange={handleRadioChange} style={{ marginBottom: '12px' }}>
                        <Radio.Button value="a">通过MetaMask交互</Radio.Button>
                        <Radio.Button value="b" onClick={showPrivateKeyModal}>传入私钥交互 <KeyOutlined /></Radio.Button>
                    </Radio.Group>
                    <Modal
                        title="输入私钥"
                        open={isModalVisible}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                        width={640}
                    >
                        <TextArea
                            rows={8}
                            placeholder="输入私钥（每行一个）"
                            value={privateKeys}
                            onChange={(e) => setPrivateKeys(e.target.value)}
                        />
                    </Modal>
                </div>

                {interactionType === 'b' && (
                    <div>
                        <Row justify="space-between" gutter={[16, 16]}>
                            <Col xs={24} sm={6} md={6} lg={5}>
                                <Select placeholder="选择链" style={{ width: '100%' }} onChange={handleChainChange}>
                                    <Option value="ethereum">Ethereum</Option>
                                    <Option value="BSC">BSC</Option>
                                    <Option value="avalanche">Avalanche</Option>
                                    <Option value="sepolia">Sepolia</Option>
                                </Select>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={14}>
                                <Input
                                    placeholder="输入你的RPC URL"
                                    style={{ width: '100%' }}
                                    value={customRpcUrl}
                                    onChange={handleCustomRpcUrlChange}
                                />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={5}>
                                <Button style={{ width: '100%' }} onClick={updateBalances}>查询余额</Button>
                            </Col>
                        </Row>
                        <div style={{ marginTop: '10px' }}>
                            <Table rowSelection={rowSelection} columns={columns} dataSource={walletData} />
                        </div>
                    </div>
                )}
            </Card>
            <br></br>

            <Card className="card-hoverable" title={<span>交互设置 <SettingOutlined /></span>} >
                <Form
                    // size="small"
                    {...layout}
                    form={form}
                    name="transactionForm"
                    layout="horizontal"
                    autoComplete="off"
                    onFinish={handleTransactionSubmit}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="dataType"
                        label="Data Type"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Radio.Group defaultValue="a" buttonStyle="solid">
                            <Radio.Button value="16">16进制</Radio.Button>
                            <Radio.Button value="abi">合约ABI</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="times"
                        label="Times"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            max={20}
                            placeholder="1"
                        />
                    </Form.Item>
                    <Form.Item
                        name="eip1559"
                        label="EIP-1559"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={useEIP1559}
                            onChange={(checked) => setUseEIP1559(checked)}
                        />
                    </Form.Item>

                    {useEIP1559 ? (
                        <>
                            <Form.Item
                                name="baseFee"
                                label="Base Fee"
                                rules={[{ required: true }]}
                            >
                                <Input addonAfter="GWEI" defaultValue="25" style={{ width: 180 }} />
                            </Form.Item>
                            <Form.Item
                                name="priorityFee"
                                label="Priority Fee"
                                rules={[{ required: true }]}
                            >
                                <Input addonAfter="GWEI" defaultValue="0.1" style={{ width: 180 }} />
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item
                            name="gasPrice"
                            label="Gas Price"
                            rules={[{ required: true }]}
                        >
                            <Input addonAfter="GWEI" defaultValue="100" style={{ width: 180 }} />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="gasLimit"
                        label="Gas Limit"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input defaultValue="21000" style={{ width: 120 }} />
                    </Form.Item>

                    <Form.Item
                        name="value"
                        label="Value"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder="0.00"
                            addonAfter=
                            {<Select defaultValue="ETH">
                                <Option value="ETH">ETH</Option>

                            </Select>}
                            defaultValue=""
                            style={{ width: 180 }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="target"
                        label="Target Address"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="0x..." />
                    </Form.Item>
                    <Form.Item
                        name="data"
                        label="Data"

                    >
                        <TextArea rows={4} placeholder="inpute data" />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                发送交易
                            </Button>
                            <Button htmlType="reset">重置</Button>
                        </Space>
                    </Form.Item>
                </Form>

            </Card>
            <br></br>

            <Card className="card-hoverable" title={<span>交互日志 <CalendarOutlined /></span>} >
                <Button onClick={handleSomeAction}>日志测试</Button>
                <Button onClick={clearLogs}>清空日志</Button>
                <div className="log-container" ref={logContainerRef}>
                    {logs.map((log, index) => (
                        <div key={index}>{log}</div>
                    ))}
                </div>

            </Card>
        </div>
    );
};

export default Contract;