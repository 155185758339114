import React, { useState } from 'react';
import { Drawer, Button } from 'antd';

const IntroductionDrawer = ({ pageTitle, pageDescription }) => {
    const [visible, setVisible] = useState(true);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                页面介绍
            </Button>
            <Drawer
                size="large"
                title={pageTitle}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                {/* 使用dangerouslySetInnerHTML渲染HTML */}
                <div dangerouslySetInnerHTML={{ __html: pageDescription }}></div>
            </Drawer>
        </>
    );
};

export default IntroductionDrawer;








// import IntroductionDrawer from './IntroductionDrawer';

// <IntroductionDrawer
//   pageTitle="页面标题"
//   pageDescription="<p>这是<b>HTML</b>格式的页面介绍。</p>"
// /><br/><br/>
