
const yuanIcon = <svg t="1706373393413" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2554" width="8" height="8"><path d="M512 512m-512 0a100 100 0 1 0 1024 0 100 100 0 1 0-1024 0Z" fill="#2c2c2c" p-id="2555"></path></svg>

export default {

    route: {
        path: '/',
        routes: [
            {
                path: '/welcome',
                name: '首页',
                icon: yuanIcon,
                component: './Welcome',
            },
            {
                path: '/walletAll',
                name: '钱包操作',
                icon: yuanIcon,
                access: 'canAdmin',
                component: './walletAll',
                routes: [
                    {
                        path: '/walletAll/walletCreate',
                        name: 'WalletCreate',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                    {
                        path: '/walletAll/walletCook',
                        name: 'WalletCook',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                    {
                        path: '/walletAll/toolsTest',
                        name: 'ToolsTest',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                    {
                        path: '/walletAll/contract',
                        name: 'Iinteraction',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                ],
            },
            {
                path: '/onChainTools',
                name: '链上工具',
                icon: yuanIcon,
                access: 'canAdmin',
                component: './onChainTools',
                routes: [
                    {
                        path: '/onChainTools/CPFP',
                        name: 'CPFP加速',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                    {
                        path: '/onChainTools/others',
                        name: 'Others',
                        icon: yuanIcon,
                        component: './Welcome',
                    },
                ],
            },
            {
                path: '/chatData',
                name: 'chatData',
                icon: yuanIcon,
                access: 'canAdmin',
                routes: [
                    {
                        path: `/chatData`,
                        name: 'chatData',
                        icon: yuanIcon,

                    },

                ],
            },
            // {
            //     name: 'Antd',
            //     icon: yuanIcon,
            //     path: '/antd',
            //     component: './ListTableList',
            //     routes: [
            //         {
            //             path: '/antd/common',
            //             name: '通用',

            //             routes: [
            //                 {
            //                     path: 'button',
            //                     name: 'Button 按钮',
            //                     icon: yuanIcon,
            //                     component: './Welcome',
            //                 },
            //                 {
            //                     path: 'floatButton',
            //                     name: 'FloatButton 悬浮按钮',
            //                     icon: yuanIcon,
            //                     component: './Welcome',
            //                 },
            //                 {
            //                     path: 'icon',
            //                     name: 'Icon 图标（未）',
            //                     icon: yuanIcon,
            //                     component: './Welcome',
            //                 },
            //             ],
            //         },
            //         {
            //             path: '/antd/layout',
            //             name: ' 布局',

            //             component: './Welcome',
            //         },
            //         {
            //             path: '/antd/more',
            //             name: '更多',

            //             component: './Welcome',
            //         },
            //     ],
            // },
            {
                path: 'https://lzzs.blog/cn',
                name: 'lzzs.blog(测试学习使用)',
                icon: yuanIcon,
            },
        ],
    },
    location: {
        pathname: '/',
    },
    appList: [
        {
            icon: <svg t="1706380158538" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="952" width="41" height="41"><path d="M480 480m-288 0a4.5 4.5 0 1 0 576 0 4.5 4.5 0 1 0-576 0Z" p-id="953"></path></svg>,
            title: '应用1',
            desc: '应用1的快捷导航0000',
            url: '/list/sub-page2',
        },
        {
            icon: <svg t="1706380158538" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="952" width="41" height="41"><path d="M480 480m-288 0a4.5 4.5 0 1 0 576 0 4.5 4.5 0 1 0-576 0Z" p-id="953"></path></svg>,
            title: '应用1',
            desc: '应用1的快捷导航0000',
            url: '/list/sub-page2',
        }, {
            icon: <svg t="1706380158538" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="952" width="41" height="41"><path d="M480 480m-288 0a4.5 4.5 0 1 0 576 0 4.5 4.5 0 1 0-576 0Z" p-id="953"></path></svg>,
            title: '应用1',
            desc: '应用1的快捷导航0000',
            url: '/list/sub-page2',
        }, {
            icon: <svg t="1706380158538" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="952" width="41" height="41"><path d="M480 480m-288 0a4.5 4.5 0 1 0 576 0 4.5 4.5 0 1 0-576 0Z" p-id="953"></path></svg>,
            title: '应用1',
            desc: '应用1的快捷导航0000',
            url: '/list/sub-page2',
        }
    ],
};