import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import { Table, Input, Button, Modal, Form, message, Popconfirm, Tooltip, Divider, Space, Card, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './gpt.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import IntroductionDrawer from '../IntroductionDrawer';

// table
function ChatTable() {
    const [form] = Form.useForm();
    const [chats, setChats] = useState([]);
    const [newChat, setNewChat] = useState({ title: '', parentId: '', content: '', No: '' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingChat, setEditingChat] = useState({ title: '', parentId: '', content: '', No: '' });
    const [fixedParentId, setFixedParentId] = useState('');
    const [startingNo, setStartingNo] = useState('');
    const [jumpToNo, setJumpToNo] = useState('all');
    const [currentNo, setCurrentNo] = useState('0');
    const [currentTitle, setCurrentTitle] = useState('');
    const [isExpandModalVisible, setIsExpandModalVisible] = useState(false);
    const [isReadingModalVisible, setIsReadingModalVisible] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const { TextArea } = Input;

    const { folderNo, chatNo } = useParams();
    let navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const modalSR = searchParams.get('modalSR') === 'true';

    useEffect(() => {
        const effectivefolderNo = folderNo === 'all' ? '' : folderNo;
        if (folderNo !== undefined) {
            fetchChats(effectivefolderNo); // 根据folderNo获取并展示相应内容
        } else {
            fetchChats(); // 没有folderNo时获取默认聊天数据
        }

    }, [folderNo]);



    useEffect(() => {
        const isModalOpen = searchParams.get('modal') === 'true';
        setIsExpandModalVisible(isModalOpen);
        // 其他逻辑...
    }, [location]);

    // if (chatNo && modalSR) {
    //     fetchChatDetails(chatNo); // 根据 chatNo 获取并设置选中的聊天数据
    // }

    // const fetchChatDetails = (chatNo) => {
    //     // 假设 chatNo 是需要查找的聊天记录的 No
    //     // 并且 chats 是包含所有聊天记录的数组
    //     const chatDetails = chats.find(chat => chat.No.toString() === chatNo);
    //     if (chatDetails) {
    //         setSelectedChat(chatDetails);
    //         setIsReadingModalVisible(true); // 显示阅读模态框
    //     } else {
    //         // 如果没有找到聊天记录，可能需要处理这种情况，比如显示一个错误消息
    //         console.log("Chat details not found.");
    //     }
    // };

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }



    const originalFetchChats = (parentId = '0') => {
        if (isLoading) return; // 如果正在加载，则不进行操作
        setIsLoading(true); // 设置加载状态为true

        axios.get('/.netlify/functions/get-chats', {
            params: { parentId } // 假设您的后端支持通过查询参数来过滤结果
        })
            .then(response => {
                const chatsData = response.data.map(item => ({
                    ...item.data,
                    key: item.ref['@ref'].id,
                })).sort((a, b) => parseFloat(a.No) - parseFloat(b.No));
                setChats(chatsData);
                setCurrentNo(parentId);
                if (parentId === '') { setCurrentNo('all') }
                message.success('记录加载成功');
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching chats:', error)
                message.error('记录加载失败');
                setIsLoading(false);
            });
    };
    const fetchChats = debounce(originalFetchChats, 500);

    const createChats = (values) => {

        if (!isLoggedIn) {
            message.warning('请先登录！');
            // 显示登录模态框的逻辑
            return;
        }
        const chatsToCreate = values.chats.map((chat, index) => {
            // 判断startingNo是否为整数且index为0
            const isStartingNoInteger = Number.isInteger(parseFloat(startingNo));
            const increment = index * 0.01;
            let noValue;

            if (isStartingNoInteger && index === 0) {
                // 如果startingNo是整数且index为0，No就是startingNo本身
                noValue = parseFloat(startingNo);
            } else {
                // 否则，No是startingNo加上增量，保留两位小数
                noValue = parseFloat((parseFloat(startingNo) + increment).toFixed(2));
            }

            return {
                ...chat,
                No: noValue,
                parentId: fixedParentId || chat.parentId,
            };
        });

        // Use Promise.all to wait for all POST requests to complete
        Promise.all(chatsToCreate.map(chat =>
            axios.post('/.netlify/functions/create-chat', chat)
        ))
            .then(() => {
                message.success('记录创建成功');
                fetchChats(); // Refresh the chat list
                form.resetFields(); // Reset form after submission
            })
            .catch(error => {
                console.error('Error creating chats:', error);
                message.error('记录创建失败');
            });
    };

    const handleFixedParentIdChange = (e) => {
        setFixedParentId(e.target.value);
    };

    const handleStartingNoChange = (e) => {
        setStartingNo(e.target.value);
    };
    const handleInputChange = (changedValues, allValues) => {
        setNewChat(allValues);
    };

    const deleteChat = (key) => {
        if (!isLoggedIn) {
            message.warning('请先登录！');
            // 显示登录模态框的逻辑
            return;
        }

        axios.delete(`/.netlify/functions/delete-chat/${key}`)
            .then(() => {
                const updatedChats = chats.filter(chat => chat.key !== key);
                setChats(updatedChats);
                message.success('记录删除成功');
            })
            .catch(error => {
                console.error('Error deleting chat:', error);
                message.error('记录删除失败');
            });
    };

    const fetchSubChats = (No) => {
        // 假设您有一个API端点来根据No获取子项目
        axios.get('/.netlify/functions/get-sub-chats', {
            params: { No } // 传递No作为参数
        })
            .then(response => {
                // 处理子项目数据，可能需要更新state来显示这些子项目
            })
            .catch(error => console.error('Error fetching sub chats:', error));
    };

    const getRowClassName = (record) => {
        // 判断No是否为整数
        return Number.isInteger(parseFloat(record.No)) ? 'highlight-row' : '';
    };

    const columns = [
        {
            title: 'No.',
            dataIndex: 'No',
            key: 'No',
            width: 42,
            render: (text) => {
                const number = parseFloat(text);
                return Number.isInteger(number) ? number : number.toFixed(2);
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
            ellipsis: {
                showTitle: false,
            },
            render: (txt) => (
                <Tooltip placement="right" title={txt} >
                    {txt}
                </Tooltip>
            ),
        },
        {
            title: '/',
            dataIndex: 'parentId',
            key: 'parentId',
            width: 30,

        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            ellipsis: {
                showTitle: false,
            },
        },

        {
            title: 'Action',
            key: 'action',
            width: 180,
            render: (text, record) => (
                <>
                    <Button size="small" onClick={() => showEditModal(record)} style={{ marginRight: 4 }}>编辑</Button>

                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => deleteChat(record.key)}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button style={{ marginRight: 4 }} size="small" danger>删除</Button>
                    </Popconfirm>
                    {Number.isInteger(parseFloat(record.No)) && (
                        <Button type="primary" size="small" onClick={() => {
                            navigate(`/chatData/${record.No}`);
                            // fetchChats(record.No)
                            setCurrentTitle(record.title)
                        }}>展开</Button>)}

                    {!Number.isInteger(parseFloat(record.No)) && ( // 非文件夹行显示阅读按钮
                        <Button size="small" onClick={() => {
                            setSelectedChat(record); // 设置选中的聊天数据
                            setIsReadingModalVisible(true); // 显示阅读模态框
                        }}>阅读</Button>
                    )}
                </>
            ),
        },
    ];

    const showEditModal = (chat) => {
        setEditingChat(chat);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (!isLoggedIn) {
            message.warning('请先登录！');
            // 显示登录模态框的逻辑
            return;
        }

        let parentIdToUpdate = editingChat.parentId;

        axios.put(`/.netlify/functions/update-chat/${editingChat.key}`, {
            ...editingChat,
            parentId: parentIdToUpdate
        })
            .then(response => {
                setIsModalVisible(false);
                fetchChats();
                message.success('记录更新成功');
            })
            .catch(error => {
                console.error('Error updating chat:', error);
                message.error('记录更新失败');
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const showLoginModal = () => {
        setIsLoginModalVisible(true);
    };

    // 登录表单的提交逻辑
    const onLoginFinish = async (values) => {
        // 调用登录API
        try {
            const response = await axios.post('/.netlify/functions/login', values);
            setToken(response.data.token); // 保存令牌
            setIsLoggedIn(true); // 更新登录状态
            setIsLoginModalVisible(false); // 关闭模态框
            message.success('登录成功');
        } catch (error) {
            console.error('登录失败:', error);
            message.error('登录失败，请重试！');
        }
    };

    const logout = () => {
        setIsLoggedIn(false);
        setToken('');
        message.info('您已退出登录。');
    };

    axios.interceptors.request.use(
        config => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    return (
        <div >
            <div>
                <IntroductionDrawer
                    pageTitle="chatData"
                    pageDescription="
                    <h2>
                    从React前端到FaunaDB和Netlify云函数的无服务器后端集成，实现一个简易的记录存储应用。</h2>
                    <h2>特点：</h2>
                    <ul>
                        <li><strong>使用了React Hooks</strong>：利用useState和useEffect来管理组件状态和生命周期。</li>
                        <li><strong>Ant Design组件广泛使用</strong>：展示了Ant Design库在实际项目中的强大用途。</li>
                        <li><strong>与后端交互</strong>：通过axios库与后端API进行交互，获取和提交聊天数据。</li>
                        <li><strong>动态表单处理</strong>：使用Form.List来处理动态表单条目的增加和删除。</li>
                        <li><strong>代码高亮显示</strong>：使用react-syntax-highlighter来显示代码高亮，提升用户体验。</li>
                        <li><strong>路由和参数处理</strong>：处理路由和URL参数，实现页面间导航和状态传递。</li>
                        <li><strong>防抖功能实现</strong>：自定义debounce函数，优化性能，避免在连续操作时频繁调用API。</li>
                        <li><strong>条件渲染和事件处理</strong>：根据状态进行条件渲染，并处理用户交互事件。</li>
                    </ul>
                    <h2>功能：</h2>
                    <ul>
                        <li><strong>聊天数据展示</strong>：展示聊天数据，包括编号、标题、内容等。</li>
                        <li><strong>动态添加聊天</strong>：允许用户动态添加新的聊天记录。</li>
                        <li><strong>编辑和删除聊天记录</strong>：提供编辑和删除按钮，以及相应的模态窗口和确认弹窗。</li>
                        <li><strong>登录功能</strong>：提供模态窗口让用户登录，管理登录状态。</li>
                        <li><strong>跳转和阅读模式</strong>：允许用户跳转到指定编号的聊天，以及通过模态窗口阅读聊天内容。</li>
                        <li><strong>复制内容到剪贴板</strong>：在阅读模态窗口中提供按钮，允许用户复制聊天内容。</li>
                        <li><strong>样式定制和响应式设计</strong>：通过Ant Design组件和CSS实现样式定制和响应式布局。</li>
                        </ul>
                        <br/>
                        <h2>后端介绍</h2>           
    <p>包含多个与FaunaDB交互的Netlify云函数，实现了CRUD（创建、读取、更新、删除）操作，以及用户登录验证功能。</p>

    <h3>特点：</h3>
    <ul>
        <li>使用了FaunaDB，一个强大的服务器less数据库服务，适合快速开发和部署。</li>
        <li>采用了JWT（Json Web Tokens）进行用户认证，增加了应用的安全性。</li>
        <li>集成了Netlify Functions，提供了无服务器执行环境，简化了部署和运维工作。</li>
    </ul>

    <h3>功能：</h3>
    <ul>
        <li><strong>创建聊天记录：</strong>通过POST请求创建新的聊天记录到FaunaDB。</li>
        <li><strong>删除聊天记录：</strong>根据ID通过DELETE请求删除指定的聊天记录。</li>
        <li><strong>获取聊天记录：</strong>根据parentId查询并返回相关聊天记录。</li>
        <li><strong>更新聊天记录：</strong>通过PUT请求更新指定ID的聊天记录。</li>
        <li><strong>用户登录：</strong>用户通过POST请求发送用户名和密码，成功认证后返回JWT。</li>
    </ul>

    <p>这些功能合起来提供了一个基本的后端服务框架，可以为需要数据库操作和用户认证的应用程序提供支持。</p>

                    </ul>
                    <p>感谢您的访问，更多内容请访问 
                        
                        <a href=https://github.com/lzzsG>lzzsG</a>
                       。</p>
                "
                />

            </div><br />
            <Card
                className="card-hoverable"
                title={<span>添加数据 </span>}
                extra={isLoggedIn ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <span>您已登录/</span>
                        <a onClick={logout}>退出登录</a >
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <a onClick={showLoginModal}>登录</a>
                    </div>
                )}
            >
                <Form form={form} layout="vertical" onFinish={createChats}>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                            <label htmlFor="fixedParentId" style={{ marginRight: 8, marginBottom: 0 }}>Fixed Parent ID:</label>
                            <Input id="fixedParentId" value={fixedParentId} onChange={handleFixedParentIdChange} placeholder="Enter fixed Parent ID / 所属文件夹" style={{ flex: 1, marginRight: 16 }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                            <label htmlFor="startingNo" style={{ marginRight: 8, marginBottom: 0 }}>Starting No:</label>
                            <Input id="startingNo" type="number" step="0.01" value={startingNo} onChange={handleStartingNoChange} placeholder="Enter starting No / 自动递进0.01" style={{ flex: 1 }} />
                        </div>
                    </div>

                    <Divider></Divider>
                    <Form.List name="chats">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} style={{ marginBottom: 8 }}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'title']}
                                            label="Title"
                                            rules={[{ required: true, message: 'Missing title' }]}
                                        >
                                            <Input.TextArea placeholder="Title" rows={3} />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'content']}
                                            label="Content"
                                            rules={[{ required: true, message: 'Missing content' }]}
                                        >
                                            <Input.TextArea
                                                placeholder="Content"
                                                rows={6}
                                                onChange={(e) => {
                                                    // This function ensures that each content input triggers a re-render
                                                    // To update the SyntaxHighlighter content in real-time
                                                    const newValues = [...form.getFieldValue('chats')];
                                                    newValues[name].content = e.target.value;
                                                    form.setFieldsValue({ chats: newValues });
                                                }}
                                            />
                                        </Form.Item>
                                        <SyntaxHighlighter language="jsx" style={solarizedlight} lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                            wrapLines>
                                            {form.getFieldValue(['chats', name, 'content']) || ''}
                                        </SyntaxHighlighter>
                                        <Space style={{ marginTop: '10px' }}>
                                            <Popconfirm
                                                title="Are you sure to delete this chat?"
                                                onConfirm={() => remove(name)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button danger icon={<MinusCircleOutlined />} >Delete</Button>
                                            </Popconfirm>
                                        </Space>
                                        <Divider></Divider>
                                    </div>
                                ))}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} >
                                        Add Chat
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Divider></Divider>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary" htmlType="submit">Create Chats</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
            <br />

            <Card
                className="card-hoverable"
                title={<span>faunaDB</span>}

                extra={<Row gutter={16} align="middle" justify="end" >

                    <Col span={8}>
                        <Input
                            addonBefore="No"
                            value={jumpToNo}
                            placeholder="all"
                            onChange={(e) => setJumpToNo(e.target.value)}
                            style={{ marginRight: '8px' }}
                        />
                    </Col>
                    <Col>
                        <Button style={{ marginRight: '16px' }} onClick={() => {

                            navigate(`/chatData/${jumpToNo}`)
                            // if (jumpToNo === 'all') { fetchChats(''); return }
                            // fetchChats(jumpToNo)
                            setCurrentTitle('')
                        }
                        }>JumpTo</Button>
                        {currentNo !== '0' && (
                            <>
                                <Button style={{ marginRight: '16px' }} onClick={() => {
                                    navigate(`/chatData/0`);
                                    // fetchChats('0')
                                    setCurrentTitle('')
                                }}>返回顶层</Button>
                                <Button onClick={() => {
                                    setIsExpandModalVisible(true)
                                    navigate(`/chatData/${currentNo}?modal=true`)
                                }}>列表阅读</Button>
                            </>
                        )}
                    </Col>
                </Row>}

            >
                <Col flex="auto" style={{ textAlign: 'lift' }}>
                    当前位置: {currentNo}{' '}{currentTitle}
                </Col>

                <Modal
                    title="展开阅读"
                    visible={isExpandModalVisible}
                    onOk={() => setIsExpandModalVisible(false)}
                    onCancel={() => setIsExpandModalVisible(false)}
                    width="80%"
                >
                    {chats.map(chat => (
                        <div key={chat.key} style={{ marginBottom: 20 }}>
                            <h3>{chat.No}: {chat.title}</h3>
                            <SyntaxHighlighter language="jsx" style={solarizedlight} lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                wrapLines>
                                {chat.content}
                            </SyntaxHighlighter>
                        </div>
                    ))}
                </Modal>

                <Modal
                    title={`阅读内容`}
                    visible={isReadingModalVisible}
                    onOk={() => setIsReadingModalVisible(false)}
                    onCancel={() => setIsReadingModalVisible(false)}
                    width="80%"
                    style={{ top: 20 }}
                    footer={[
                        <Button key="copy" type="primary" onClick={() => {
                            navigator.clipboard.writeText(selectedChat.content).then(() => {
                                message.success('内容复制成功');
                            }, () => {
                                message.error('内容复制失败');
                            });
                        }}>
                            复制内容
                        </Button>,
                    ]}
                >
                    {selectedChat && (
                        <div>
                            <h3>{selectedChat.No} - {selectedChat.title}</h3>
                            <SyntaxHighlighter language="jsx" style={solarizedlight} lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                wrapLines>
                                {selectedChat.content}
                            </SyntaxHighlighter>
                        </div>
                    )}
                </Modal>

                <Modal
                    title="登录"
                    visible={isLoginModalVisible}
                    onCancel={() => setIsLoginModalVisible(false)}
                    footer={null}
                >
                    <Form onFinish={onLoginFinish}>
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: '请输入用户名!' }]}
                        >
                            <Input placeholder="用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入密码!' }]}
                        >
                            <Input.Password placeholder="密码" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Table
                    rowClassName={getRowClassName}
                    columns={columns}
                    dataSource={chats}
                    pagination={{ pageSize: 20 }}
                />
                <Modal title="Edit Chat" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Input
                        name="title"
                        value={editingChat.title}
                        onChange={(e) => setEditingChat({ ...editingChat, title: e.target.value })}
                        placeholder="Title"
                    />
                    <Input
                        name="parentId"
                        value={editingChat.parentId}
                        onChange={(e) => setEditingChat({ ...editingChat, parentId: e.target.value })}
                        placeholder="parentId"
                        style={{ margin: '10px 0' }}
                    />
                    <Input
                        name="No"
                        value={editingChat.No}
                        onChange={(e) => setEditingChat({ ...editingChat, No: e.target.value })}
                        placeholder="No"
                        style={{ margin: '10px 0' }}
                    />
                    <TextArea
                        name="content"
                        value={editingChat.content}
                        onChange={(e) => setEditingChat({ ...editingChat, content: e.target.value })}
                        placeholder="Content"
                        rows={4}
                    />
                </Modal>
            </Card >
        </div >
    );
}

export default ChatTable;