import {
    GithubFilled,
    InfoCircleFilled,
    PlusCircleFilled,
    QuestionCircleFilled,
    SearchOutlined,
} from '@ant-design/icons';
import { GithubOutlined, TwitterOutlined, LinkedinOutlined, FireFilled, LinkOutlined, DollarOutlined, AlertOutlined } from '@ant-design/icons';

import React, { useEffect } from 'react';
import { PageContainer, ProCard, ProLayout, DefaultFooter } from '@ant-design/pro-components';
import { Input, Avatar, Menu, Dropdown, Popover, Space, ConfigProvider, Layout, Row, Col, Icon, Card, List, Typography, Divider } from 'antd';
import { useState } from 'react';
import { useNavigate, useLocation, Link, BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import defaultProps from './_defaultProps';
import RealTimePrices from './zujian/tokenPrice';


import HomePage from './tops/homepage';
import Welcome from './tops/Welcome';

import WalletAll from './walletPart/walletAll';
import Contract from './contractPart/contract';
import WalletCook from './walletPart/WalletCook';
import WalletCreate from './walletPart/WalletCreate';
import WalletTest from './walletPart/walletTest';

import ToolsAll from './toolPart/toolsAll';
import CPFP from './toolPart/cpfp';
import TestApp from './toolPart/test';


import ChatTable from './chatGPT/chatData';
import { Alert, Button, Modal } from 'antd';

export default () => {

    const navigate = useNavigate();
    const location = useLocation();
    const settings = {
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
    };

    const [pathname, setPathname] = useState('/welcome');
    const [isCollapsed, setIsCollapsed] = useState(false);

    const popoverContent = (
        <div>
            <p>个人资料</p>
            <p>资料设置</p>
            <p>退出登录</p>
            {/* 更多内容 */}
        </div>
    );

    // 默认始终显示Modal
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleOk = () => {
        setIsModalVisible(false); // 点击确定后关闭Modal
    };


    return (
        <div
            id="test-pro-layout"
            style={{
                height: '100vh',
            }}
        >
            <ConfigProvider
                theme={{
                    "token": {
                        "colorPrimary": "#004e81",
                        "colorInfo": "#004e81",
                        "colorSuccess": "#368400",
                        "colorWarning": "#7f5d00",
                        "colorError": "#780000",
                        "borderRadius": 12,
                        "wireframe": false,
                        "fontSize": 16,
                        "colorTextBase": "#000000"
                    },
                    "components": {
                        "Button": {
                            "algorithm": true
                        },
                        Table: {
                            cellPaddingBlock: 12,
                            cellFontSize: 14,
                            cellPaddingInline: 8,
                            rowHoverBg: "#3c82b11c",
                            headerBg: "#f5f5f5",
                        },
                    }
                }}
            >

                <ProLayout
                    siderWidth={212}
                    onCollapse={(collapsed) => {
                        setIsCollapsed(collapsed);
                    }}
                    menuExtraRender={() => {
                        return (
                            <div style={{ color: '#000', textAlign: 'center' }}>
                                {/* 在额外内容区域中添加自定义内容 */}
                                <p>感谢您的访问，更多内容请访问<a href='https://github.com/lzzsG'>lzzsG</a>。</p>
                                <Divider></Divider>
                                <RealTimePrices isCollapsed={isCollapsed} />

                            </div>
                        );
                    }}

                    footerRender={() => {
                        return (
                            <div style={{ color: '#000', textAlign: 'center', fontWeight: 'bold', }}>
                                <div>Lzzzzz

                                    <a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a>
                                    <a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a><a href="https://github.com/lzzsG" style={{ color: 'inherit' }}>
                                        <GithubOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                                    </a>
                                    <TwitterOutlined style={{ fontSize: '16px', margin: '10px' }} />

                                    <LinkedinOutlined style={{ fontSize: '16px' }} />

                                    <FireFilled style={{ fontSize: '16px' }} />
                                    <FireFilled style={{ fontSize: '16px' }} />
                                    <FireFilled style={{ fontSize: '16px' }} />

                                    由于现有区块链相关与理想偏差太大 暂时放弃
                                    <FireFilled style={{ fontSize: '16px' }} />
                                    <FireFilled style={{ fontSize: '16px' }} />
                                    <FireFilled style={{ fontSize: '16px' }} />
                                </div>
                            </div>
                        );
                    }}
                    // 'rgba(0, 78, 129, 1)',
                    token={{
                        bgLayout: 'rgba(0, 0, 0, 0.04)',
                        // colorTextAppListIcon: '#000',
                        colorBgAppListIconHover: 'rgba(0, 0, 0, 0.12)',
                        header: {
                            // colorTextMenuSelected: '#000',
                            colorBgMenuItemSelected: 'rgba(0, 0, 0, 0.04)',
                            heightLayoutHeader: 41,
                            // colorBgMenuItemHover: 'rgba(0, 0, 0, 0.12)',
                            // colorBgRightActionsItemHover: 'rgba(0, 0, 0, 0.12)',
                            // colorBgHeader: 'rgba(0, 0, 0, 0.12)',
                            colorTextMenuSelected: "#203D69",

                        },
                        sider: {
                            colorTextMenuSelected: "#203D69",
                            colorBgMenuItemSelected: 'rgba(0, 0, 0, 0.12)',
                            colorBgMenuItemHover: 'rgba(0, 0, 0, 0.12)',
                        },
                    }}
                    bgLayoutImgList={
                        [
                            {
                                src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                                left: 85,
                                bottom: 100,
                                height: '303px',
                            },
                            {
                                src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
                                bottom: -68,
                                right: -45,
                                height: '303px',
                            },
                            {
                                src: 'https://i.imgur.com/cPdykQA.png',
                                bottom: 0,
                                left: 0,
                                width: '40px',
                            },
                        ]}
                    title="Lixxx"

                    logo="https://imgur.com/2b40LAu.png"
                    {...defaultProps}
                    location={location}
                    menu={{
                        type: 'group',
                    }}

                    actionsRender={(props) => {

                        if (props.isMobile) return [];
                        return [

                            props.layout !== 'side' && document.body.clientWidth > 900 ? (

                                <div
                                    key="SearchOutlined"
                                    aria-hidden
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginInlineEnd: 24,
                                        height: '40px'
                                    }}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >

                                    <Input
                                        style={{
                                            borderRadius: 4,
                                            marginInlineEnd: 12,
                                            backgroundColor: 'rgba(0,0,0,0.04)',
                                        }}
                                        prefix={
                                            <SearchOutlined
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.20)',
                                                }}
                                            />
                                        }
                                        placeholder="搜索页面"
                                        bordered={false}
                                    />
                                    <PlusCircleFilled
                                        style={{
                                            color: 'var(--ant-primary-color)',
                                            fontSize: 24,
                                        }}
                                    />
                                </div>
                            ) : undefined,

                            <InfoCircleFilled key="InfoCircleFilled" />,
                            <QuestionCircleFilled key="QuestionCircleFilled" />,
                            <GithubFilled key="GithubFilled" />,
                            <Popover content={popoverContent} trigger="click" key="avatarPopover">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInlineEnd: 24,
                                    height: '40px',
                                    marginLeft: '18px'
                                }}>
                                    <Avatar
                                        src="https://imgur.com/2b40LAu.png"
                                        size="default "
                                        style={{ cursor: 'pointer' }}
                                        title="?"
                                    />
                                    <span style={{ marginLeft: '6px', color: '#000' }}>Lixxx</span>
                                </div>

                            </Popover>,
                        ];
                    }}
                    menuFooterRender={(props) => {
                        if (props?.collapsed) return undefined;
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    paddingBlockStart: 12,
                                }}
                            >
                                <div>Blockchain App ©2024</div>
                                <div>by @LzzsWeb3</div>
                            </div>
                        );
                    }}
                    onMenuHeaderClick={() => navigate('/')}
                    menuItemRender={(item, dom) => (
                        <div
                            onClick={() => {
                                console.log("Navigating to:", item.path);
                                setPathname(item.path || '/welcome');
                                navigate(item.path || '/welcome');
                            }}
                        >
                            {dom}
                        </div>
                    )}
                    {...settings}
                >
                    <Alert
                        banner
                        message="警告 / Warning"
                        description="请注意，本站点包含的虚拟货币和数字资产功能仅供学习和展示之用。使用这些功能可能会导致资产损失，一旦使用，即表示您同意承担所有相关风险。"
                        type="warning"
                        showIcon

                    />


                    <Modal
                        title="警告 / Warning"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleOk} // 取消也关闭Modal，或根据需要使用不同逻辑
                    >
                        <div class="modal">

                            <div class="content-cn">
                                <p>请注意，本站点包含的虚拟货币和数字资产功能仅供学习和展示之用。使用这些功能可能会导致资产损失，一旦使用，即表示您同意承担所有相关风险。</p>
                            </div>
                            <div class="content-en">
                                <p>Please note, the virtual currency and digital asset features contained in this site are for learning and demonstration purposes only. Using these features may result in asset loss, and by using them, you agree to bear all associated risks.</p>
                            </div>
                        </div>
                    </Modal>
                    <PageContainer
                        header={{
                            title: ''
                        }}
                        style={{

                        }}>

                        <div
                            style={{
                                maxWidth: '1200px',
                                margin: '0 auto',
                                minHeight: 900,
                                paddingTop: '24px', // 顶部内边距
                                paddingBottom: '24px', // 底部内边距
                            }}
                        >
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/welcome" element={<HomePage />} />

                                <Route path="/walletAll" element={<WalletAll />} />
                                <Route path="/walletAll/contract" element={<Contract />} />
                                <Route path="/walletAll/walletCreate" element={<WalletCreate />} />
                                <Route path="/walletAll/walletCook" element={<WalletCook />} />
                                <Route path="/walletAll/toolsTest" element={<WalletTest />} />

                                <Route path="/onChainTools" element={<ToolsAll />} />
                                <Route path="/onChainTools/CPFP" />
                                <Route path="/onChainTools/others" element={<TestApp />} />

                                <Route path="/chatData/:folderNo/:chatNo" element={<ChatTable />} />
                                <Route path="/chatData/:folderNo" element={<ChatTable />} />
                                <Route path="/chatData" element={<ChatTable />} />



                                {/* 定义其他路由 */}
                            </Routes>

                        </div>

                    </PageContainer>

                </ProLayout >
            </ConfigProvider>
        </div >
    );
};