import React from 'react';
import { Card, Col, Row } from 'antd';
import { useNavigate, useLocation, Link, BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import './wallet.css'


const { Meta } = Card;



function WalletAll() {


    const navigate = useNavigate();
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable className="card-hoverable" onClick={() => navigate('/walletAll/contract')}
                        cover={<img alt="example" src="https://i.imgur.com/vMVZdCL.jpg" />}
                    >
                        <Meta title="Contract Iinteraction" description="contract contract" />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable className="card-hoverable" onClick={() => navigate('/walletAll/toolsTest')}
                        cover={<img alt="example" src="https://i.imgur.com/asqxPFh.jpg" />}
                    >
                        <Meta title="Tools Test" description="toolsTest toolsTest" />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable className="card-hoverable" onClick={() => navigate('/walletAll/walletCreate')}
                        cover={<img alt="example" src="https://i.imgur.com/vmxrBHF.jpg" />}
                    >
                        <Meta title="Wallet Create" description="walletCreate walletCreate" />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable className="card-hoverable" onClick={() => navigate('/walletAll/walletCook')}
                        cover={<img alt="example" src="https://i.imgur.com/xjVsRfc.jpg" />}
                    >
                        <Meta title="Wallet Cook" description="walletCook walletCook" />
                    </Card>
                </Col>
            </Row>
        </div>
    );

}
export default WalletAll;