import React, { useState, useMemo, useRef, useEffect } from 'react';
import { ethers } from 'ethers';
import { Upload, Button, Table, Checkbox, Input, Row, Col, Card, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../customStyles.css';
import IntroductionDrawer from '../IntroductionDrawer';

//操作钱包页面总
const WalletBalanceChecker = () => {
    const [wallets, setWallets] = useState([]);


    // 一、导入钱包--上传并读取钱包文件
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const uploadedWallets = text.split('\n').map(line => {
                const [address, privateKey] = line.split('---');
                return { address, privateKey, isLoading: false };
            });
            setWallets(uploadedWallets);
        };
        reader.readAsText(file);
        return false; // Prevent upload
    };


    //二、参数设置--rpc/gasPricee/gasLimit/自动获取 Gas/provider
    const [gasPrice, setGasPrice] = useState('');
    const [rpcUrl, setRpcUrl] = useState('https://sepolia.rpc.thirdweb.com'); // 默认 RPC
    const [gasLimit, setGasLimit] = useState('21000'); // 默认 Gas Limit
    const provider = useMemo(() => new ethers.providers.JsonRpcProvider(rpcUrl), [rpcUrl]);

    // 自动获取 gasPrice
    const fetchGasPrice = async () => {
        try {
            const fetchedGasPrice = await provider.getGasPrice();
            setGasPrice(ethers.utils.formatUnits(fetchedGasPrice, 'gwei'));
        } catch (error) {
            console.error("Error fetching gas price:", error);
        }
    };


    // 三、选择钱包批量查询--选中钱包/更新钱包余额/Loading状态
    const [selectedWallets, setSelectedWallets] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    //查询选中钱包余额
    const checkBalances = async () => {
        // 设置选中钱包的 isLoading 为 true
        setWallets(selectedWallets.map(wallet => ({
            ...wallet,
            isLoading: true
        })));
        try {
            const updatedWallets = await Promise.all(selectedWallets.map(async wallet => {
                const balance = await provider.getBalance(wallet.address);
                return { ...wallet, balance: ethers.utils.formatEther(balance), isLoading: false };
            }));

            // 更新钱包余额并重置 isLoading 状态
            setWallets(wallets.map(wallet => {
                const updatedWallet = updatedWallets.find(w => w.address === wallet.address);
                return updatedWallet ? updatedWallet : { ...wallet, isLoading: false };
            }));
        } catch (error) {
            console.error("Error in fetching balances:", error);
            // 错误处理: 重置所有选中钱包的 isLoading 状态
            setWallets(wallets.map(wallet => ({ ...wallet, isLoading: selectedWallets.includes(wallet.address) ? false : wallet.isLoading })));
        }
    };

    // 勾选钱包
    const handleSelectChange = (wallet, isChecked) => {
        if (isChecked) {
            // 如果勾选，添加整个钱包对象到选中列表
            setSelectedWallets(prevSelected => [...prevSelected, wallet]);
        } else {
            // 如果取消勾选，从选中列表移除该钱包对象
            setSelectedWallets(prevSelected => prevSelected.filter(w => w.address !== wallet.address));
        }

    };

    // 全选处理
    const handleSelectAll = (e) => {
        setIsAllSelected(e.target.checked);
        if (e.target.checked) {
            // 如果全选，添加所有钱包对象到选中列表
            setSelectedWallets(wallets);
        } else {
            // 如果取消全选，清空选中列表
            setSelectedWallets([]);
        }
    };

    //顺序模式和快速模式
    const [sendMode, setSendMode] = useState('sequential'); // 'sequential' 或 'fast'

    // 切换模式的函数
    const toggleSendMode = () => {
        setSendMode(sendMode === 'sequential' ? 'fast' : 'sequential');
    };
    // 辅助函数：执行单个分发交易
    const sendDistributeTransaction = async (mainWallet, toAddress, amount, gasPrice, gasLimit, nonce = null) => {
        const transaction = {
            to: toAddress,
            value: ethers.utils.parseEther(amount),
            gasPrice: ethers.utils.parseUnits(gasPrice, 'gwei'),
            gasLimit: ethers.BigNumber.from(gasLimit),
            nonce: nonce
        };
        const txResponse = await mainWallet.sendTransaction(transaction);
        addLog(`From ${mainWallet.address} to ${toAddress}: ${amount} ETH, Transaction hash: ${txResponse.hash}`);

        if (sendMode === 'sequential') {
            await txResponse.wait(); // 在顺序模式下等待交易确认
        }
    };



    // 四、批量归集--收款地址/归集数量/预估费用
    const [receiverAddress, setReceiverAddress] = useState(''); // 收款地址
    const [totalGasCost, setTotalGasCost] = useState(''); // 预期的总 Gas 费用
    const [collectAmount, setCollectAmount] = useState('');
    const [totalCollection, setTotalCollection] = useState('');

    const batchCollect = async () => {
        if (!ethers.utils.isAddress(receiverAddress)) {
            alert("无效的收款地址");
            return;
        }
        if (!gasPrice || !gasLimit) {
            alert("请设置 Gas 价格和 Gas Limit");
            return;
        }
        if (!collectAmount) {
            alert("请输入每个钱包归集的主币数量");
            return;
        }

        //打印执行前余额
        const balance = await provider.getBalance(receiverAddress);
        addLog(`${receiverAddress} 执行前余额：${ethers.utils.formatEther(balance)}`)

        try {
            // 逻辑处理
            const totalCollectAmount = ethers.utils.parseEther(collectAmount).mul(selectedWallets.length);
            const totalCost = ethers.utils.parseUnits(gasPrice, 'gwei').mul(ethers.BigNumber.from(gasLimit)).mul(selectedWallets.length);
            //预估费用
            setTotalGasCost(ethers.utils.formatEther(totalCost));
            setTotalCollection(ethers.utils.formatEther(totalCollectAmount));
            // 执行归集操作

            selectedWallets.forEach(async (wallet) => {
                const signer = new ethers.Wallet(wallet.privateKey, provider);
                const transaction = {
                    to: receiverAddress,
                    value: ethers.utils.parseEther(collectAmount),
                    gasPrice: ethers.utils.parseUnits(gasPrice, 'gwei'),
                    gasLimit: ethers.BigNumber.from(gasLimit), // 使用 gasLimit
                };
                const txResponse = await signer.sendTransaction(transaction);

                addLog(`From ${wallet.address} to ${receiverAddress}: ${collectAmount} ETH,
                Transaction hash: ${txResponse.hash}`);
            });

        } catch (error) {
            console.error("Error in batch collection:", error);
        }
    };


    // 五、批量分发--主账户私钥/分发数量/预估费用
    const [mainWalletPrivateKey, setMainWalletPrivateKey] = useState('');
    const [amountToSend, setAmountToSend] = useState(''); // 要发送的金额
    const [totalDistribution, setTotalDistribution] = useState(''); // 要发送的金额

    const batchDistribute = async () => {
        if (!mainWalletPrivateKey) {
            alert("请输入主账户的私钥");
            return;
        }
        if (!gasPrice || !gasLimit) {
            alert("请设置 Gas 价格和 Gas Limit");
            return;
        }
        if (!amountToSend) {
            alert("请输入每个钱包发送的ETH数量");
            return;
        }



        try {
            // 逻辑处理
            const totalDistributeAmount = ethers.utils.parseEther(amountToSend).mul(selectedWallets.length);
            const totalCost = ethers.utils.parseUnits(gasPrice, 'gwei').mul(ethers.BigNumber.from(gasLimit)).mul(selectedWallets.length);
            //预估费用
            setTotalGasCost(ethers.utils.formatEther(totalCost));
            setTotalDistribution(ethers.utils.formatEther(totalDistributeAmount));
            // 执行分发操作
            const mainWallet = new ethers.Wallet(mainWalletPrivateKey, provider);

            //打印执行前余额
            const balance = await provider.getBalance(mainWallet.address);
            addLog(`${mainWallet.address} 执行前余额：${ethers.utils.formatEther(balance)}`)

            if (sendMode === 'sequential') {
                for (const wallet of selectedWallets) {
                    await sendDistributeTransaction(mainWallet, wallet.address, amountToSend, gasPrice, gasLimit);
                }
            } else {
                let nonce = await provider.getTransactionCount(mainWallet.address, 'latest');
                selectedWallets.forEach(wallet => {
                    sendDistributeTransaction(mainWallet, wallet.address, amountToSend, gasPrice, gasLimit, nonce);
                    nonce++;
                });
            }
        } catch (error) {
            console.error("Error in batch distribution:", error);
        }
    };


    // 六、表格定义--选择/钱包地址/余额/加载中...
    const columns = [
        {
            title: '选择',
            key: 'select',
            render: (_, wallet) => (
                <Checkbox
                    checked={selectedWallets.some(w => w.address === wallet.address)}
                    onChange={e => handleSelectChange(wallet, e.target.checked)}
                />
            ),
        },
        {
            title: '钱包地址',
            dataIndex: 'address',
        },
        {
            title: '余额',
            key: 'balance',
            render: (_, wallet) => (
                wallet.isLoading ? "加载中..." : wallet.balance
            ),
        },
    ];


    //七、打印日志
    const [logs, setLogs] = useState([]);
    const logContainerRef = useRef(null);

    const addLog = (message) => {
        setLogs((prevLogs) => [...prevLogs, message]);
        console.log(message);
    };

    const handleSomeAction = () => {
        addLog(`--------执行了某些操作--------`);
    };

    const clearLogs = () => {
        setLogs([]);
    };

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logs]);


    //八、页面布局--导入钱包/参数设置/选择查询/批量归集批量分发/钱包列表
    return (
        <div style={{ maxWidth: '880px', margin: '0 auto' }}>
            <IntroductionDrawer
                pageTitle="WalletCook"
                pageDescription="
                学习测试2
                <h3>特点：</h3>
                <ul>
                    <li>使用了React的函数组件和Hooks，如useState, useMemo, useRef, useEffect。</li>
                    <li>集成了ethers.js库来与以太坊区块链交互。</li>
                    <li>利用Ant Design的UI组件，如Upload, Button, Table, Checkbox, Input等，实现了优雅的用户界面。</li>
                    <li>支持上传钱包文件，自动读取钱包地址和私钥。</li>
                    <li>提供了RPC URL、Gas价格和Gas限制的设置功能。</li>
                    <li>允许对选定的钱包执行批量查询和批量操作。</li>
                </ul>
            
                <h3>功能：</h3>
                <ul>
                    <li>导入钱包：用户可以上传包含钱包地址和私钥的文件。</li>
                    <li>参数设置：用户可以设置RPC URL、Gas价格和Gas限制。</li>
                    <li>批量查询余额：对选中的钱包执行余额查询。</li>
                    <li>批量归集：将选中钱包的余额归集到指定的收款地址。</li>
                    <li>批量分发：从主账户向选中的钱包分发指定数量的ETH。</li>
                    <li>显示钱包列表和余额：在表格中展示导入的钱包及其余额。</li>
                    <li>操作日志：记录用户的操作及结果。</li>
                </ul>"
            /><br /><br />

            <Row gutter={0}>

                {/* 导入钱包 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="导入钱包">
                        <Upload beforeUpload={handleFileUpload} maxCount={1}>
                            <Button icon={<UploadOutlined />}>导入钱包文件</Button>
                        </Upload>

                    </Card>
                </Col>

                {/* 参数设置 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="参数设置">
                        <Form.Item label="RPC URL">
                            <Input placeholder="RPC URL" value={rpcUrl} onChange={(e) => setRpcUrl(e.target.value)} />
                        </Form.Item>
                        <Form layout="inline">
                            <Form.Item label="Gas Price (Gwei)">
                                <Input placeholder="Gas Price (Gwei)" value={gasPrice} onChange={(e) => setGasPrice(e.target.value)} /></Form.Item> <Form.Item >
                                <Button onClick={fetchGasPrice}>自动获取</Button>
                            </Form.Item>
                            <Form.Item label="Gas Limit">
                                <Input placeholder="Gas Limit" value={gasLimit} onChange={(e) => setGasLimit(e.target.value)} />
                            </Form.Item>
                        </Form>

                    </Card>
                </Col>

                {/* 选择查询 */}
                <Col span={12}>
                    <Card className="card-hoverable" title="选择查询">
                        <Checkbox onChange={handleSelectAll} checked={isAllSelected}>全选</Checkbox>
                        <Button onClick={checkBalances}>查询余额</Button>
                    </Card>
                </Col>

                {/* 选择查询 */}
                <Col span={12}>
                    <Card className="card-hoverable" title="切换模式">
                        <Button onClick={toggleSendMode}>
                            切换到{sendMode === 'sequential' ? '快速' : '顺序'}模式
                        </Button>
                        <span>当前模式：{sendMode === 'sequential' ? '顺序' : '快速'}</span>
                    </Card>
                </Col>

                {/* 批量归集 */}
                <Col span={12}>
                    <Card className="card-hoverable" title="批量归集">
                        <Input placeholder="收款地址" value={receiverAddress} onChange={(e) => setReceiverAddress(e.target.value)} />
                        <Input placeholder="每个钱包归集的ETH数量" value={collectAmount} onChange={(e) => setCollectAmount(e.target.value)} />
                        <Button onClick={batchCollect} type="primary">执行归集</Button>
                        <p>预期总 Gas 费用（ETH）: {totalGasCost}</p>
                        <p>预期总归集金额（ETH）: {totalCollection}</p>

                    </Card>
                </Col>

                {/* 批量分发 */}
                <Col span={12}>
                    <Card className="card-hoverable" title="批量分发">
                        <Input.Password placeholder="输入主账户私钥" value={mainWalletPrivateKey} onChange={(e) => setMainWalletPrivateKey(e.target.value)} />
                        <Input placeholder="每个钱包发送的ETH数量" value={amountToSend} onChange={(e) => setAmountToSend(e.target.value)} />
                        <Button onClick={batchDistribute} type="primary">执行分发</Button>
                        <p>预期总 Gas 费用（ETH）: {totalGasCost}</p>
                        <p>预期总分发金额（ETH）: {totalDistribution}</p>
                    </Card>
                </Col>

                {/* 钱包列表 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="钱包列表">
                        <Table dataSource={wallets} columns={columns} rowKey="address" />
                    </Card>
                </Col>

                {/* 日志 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="日志">
                        <Button onClick={handleSomeAction}>执行操作</Button>
                        <Button onClick={clearLogs}>清空日志</Button>
                        <div className="log-container" ref={logContainerRef}>
                            {logs.map((log, index) => (
                                <div key={index}>{log}</div>
                            ))}
                        </div>
                    </Card>
                </Col>

            </Row>
        </div>
    );
};

export default WalletBalanceChecker;