import React, { useState } from 'react';
import { Row, Col, Card, Input, Button, List, Upload, Modal, message } from 'antd';
import { ethers } from 'ethers';
import { UploadOutlined, PlusOutlined, DownloadOutlined, KeyOutlined, ClearOutlined } from '@ant-design/icons';
import '../index.css';
import './wallet.css'

//钱包创建总页面--创建钱包/格式化时间/导出钱包/导入钱包
function WalletCreate() {
    const [count, setCount] = useState(0);
    const [wallets, setWallets] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [privateKeys, setPrivateKeys] = useState('');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    // 创建钱包
    const handleCreate = () => {
        const newWallets = [];
        for (let i = 0; i < count; i++) {
            const wallet = ethers.Wallet.createRandom();
            const walletInfo = {
                address: wallet.address,
                privateKey: wallet.privateKey
            };
            newWallets.push(walletInfo);
        }
        setWallets(newWallets);
    };

    // 将 UTC 时间调整为北京时间 (UTC+8)
    const formatBeijingTime = (date) => {
        const beijingOffset = 8 * 60; // 8小时，以分钟为单位
        const localOffset = date.getTimezoneOffset(); // 本地时区偏移，以分钟为单位
        const offsetTime = new Date(date.getTime() + (beijingOffset + localOffset) * 60000);

        // 格式化时间
        const year = offsetTime.getFullYear();
        const month = (offsetTime.getMonth() + 1).toString().padStart(2, '0');
        const day = offsetTime.getDate().toString().padStart(2, '0');
        const hours = offsetTime.getHours().toString().padStart(2, '0');
        const minutes = offsetTime.getMinutes().toString().padStart(2, '0');
        const seconds = offsetTime.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };

    // 导出钱包
    const downloadWallets = () => {
        const walletText = wallets.map(wallet => `${wallet.address}---${wallet.privateKey}`).join('\n');
        const blob = new Blob([walletText], { type: 'text/plain' });

        // 获取北京时间并格式化
        const now = new Date();
        const timestamp = formatBeijingTime(now);

        // 构建文件名，包含时间和钱包个数
        const filename = `@${timestamp}__${wallets.length} wallets.txt`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;  // 使用包含时间和数量的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // 导入钱包
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const uploadedWallets = text.split('\n').map(line => {
                const [address, privateKey] = line.split('---');
                return { address, privateKey };
            });
            setWallets(uploadedWallets);
        };
        reader.readAsText(file);
        return false; // Prevent upload
    };


    // 弹出式对话框中的内容
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const newWallets = privateKeys.split('\n').map(key => {
            try {
                const wallet = new ethers.Wallet(key);
                return {
                    address: wallet.address,
                    privateKey: wallet.privateKey
                };
            } catch (error) {
                console.error("Invalid private key:", key);
                return null;
            }
        }).filter(wallet => wallet !== null);
        setWallets(wallets => [...wallets, ...newWallets]);
        setIsModalOpen(false);
        setPrivateKeys('');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setPrivateKeys('');
    };


    // 显示确认清空钱包的弹出式对话框
    const showConfirmModal = () => {
        setIsConfirmModalOpen(true);
    };

    const handleConfirmOk = () => {
        setWallets([]);
        setIsConfirmModalOpen(false);
        message.success('钱包已清空');
    };

    const handleConfirmCancel = () => {
        setIsConfirmModalOpen(false);
    };



    //主页面
    return (
        <div>
            <Row gutter={16}>

                {/* 钱包操作 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="钱包操作">
                        <Input type="number" min={1} max={100} placeholder="请输入要创建的钱包数量" onChange={e => setCount(parseInt(e.target.value, 10))} />
                        <br /> <br />
                        <Button icon={<PlusOutlined />} type="primary" onClick={handleCreate}>创建钱包</Button>
                        <Button icon={<DownloadOutlined />} onClick={downloadWallets} style={{ margin: '0 10px' }}>导出钱包</Button>
                        <Button icon={<ClearOutlined />} onClick={showConfirmModal} >清空钱包</Button>
                        <Button icon={<KeyOutlined />} onClick={showModal} style={{ margin: '0 10px' }}>导入私钥</Button>
                        <Upload beforeUpload={handleFileUpload} maxCount={1}>
                            <Button icon={<UploadOutlined />}>导入钱包文件</Button>
                        </Upload>
                    </Card>
                    <br></br>
                </Col>



                {/* 钱包列表 */}
                <Col span={24}>
                    <Card className="card-hoverable" title="钱包列表">
                        <List
                            itemLayout="horizontal"
                            dataSource={wallets}
                            renderItem={wallet => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={`地址: ${wallet.address}`}
                                        description={`私钥: ${wallet.privateKey}`}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>

            {/* 弹出式对话框 */}
            <Modal title="导入私钥" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input.TextArea
                    rows={4}
                    placeholder="输入私钥，每行一个"
                    value={privateKeys}
                    onChange={e => setPrivateKeys(e.target.value)}
                />
            </Modal>

            <Modal
                title="确认清空钱包"
                open={isConfirmModalOpen}
                onOk={handleConfirmOk}
                onCancel={handleConfirmCancel}
                okText="继续清空"
                cancelText="返回"
            >
                <p>请在清空前确保已经导出重要钱包。</p>
            </Modal>


        </div>
    );
}

export default WalletCreate;