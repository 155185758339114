import React, { useState, useEffect } from 'react';
import { Divider, Typography } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import axios from 'axios';


function RealTimePrices({ isCollapsed }) {
    const [btcPrice, setBtcPrice] = useState('');
    const [ethPrice, setEthPrice] = useState('');


    const API_KEY = '815bd278-05ec-4940-89d2-0f18d047ec7a'; // Replace with your Live Coin Watch API key

    const fetchPrices = async () => {
        try {
            const headers = {
                'x-api-key': API_KEY
            };

            // BTC Price
            const btcResponse = await axios.post('https://api.livecoinwatch.com/coins/single', {
                currency: 'USD',
                code: 'BTC',
                meta: true
            }, { headers });

            setBtcPrice(btcResponse.data.rate.toFixed(0));

            // ETH Price
            const ethResponse = await axios.post('https://api.livecoinwatch.com/coins/single', {
                currency: 'USD',
                code: 'ETH',
                meta: true
            }, { headers });

            setEthPrice(ethResponse.data.rate.toFixed(0));
        } catch (error) {
            console.error('Error fetching real-time prices:', error);
        }
    };

    useEffect(() => {
        fetchPrices();
        // Set an interval for fetching prices periodically
        const interval = setInterval(() => {
            fetchPrices();
        }, 60000); //  Fetch prices every 60 seconds

        return () => clearInterval(interval);
    }, []);

    if (!isCollapsed) {
        return (
            <div>
                <Typography.Text><DollarOutlined /> ETH: ${ethPrice}</Typography.Text><br />
                <Typography.Text><DollarOutlined /> BTC: ${btcPrice}</Typography.Text>
                <Divider></Divider>
            </div>
        );
    } else {
        return null; // 当isCollapsed为true时，不渲染任何内容
    }
}

export default RealTimePrices;